<template>
  <v-card flat>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left"></th>
            <th class="text-left teal--text">Экономические последствия</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Рекомендаций к включению ЛП</td>
            <td class="teal--text">Если баллов >=6</td>
          </tr>
          <tr>
            <td>Рекомендаций к включению орфанного ЛП</td>
            <td class="teal--text">Если баллов >=2</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: 'RecommendEPScore',
}
</script>

<style scoped></style>
